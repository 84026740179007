export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        AnalyticsChart: ['BarChart', 'TableChart', 'TimeSeriesChart'],
        Aspect: ['SchemaMetadata'],
        BrowsableEntity: [
            'Chart',
            'Dashboard',
            'DataFlow',
            'DataJob',
            'Dataset',
            'MLFeatureTable',
            'MLModel',
            'MLModelGroup',
            'Notebook',
        ],
        Entity: [
            'AccessTokenMetadata',
            'Assertion',
            'Chart',
            'Container',
            'CorpGroup',
            'CorpUser',
            'Dashboard',
            'DataFlow',
            'DataHubPolicy',
            'DataHubRole',
            'DataHubView',
            'DataJob',
            'DataPlatform',
            'DataPlatformInstance',
            'DataProcessInstance',
            'DataProduct',
            'Dataset',
            'Domain',
            'GlossaryNode',
            'GlossaryTerm',
            'MLFeature',
            'MLFeatureTable',
            'MLModel',
            'MLModelGroup',
            'MLPrimaryKey',
            'Notebook',
            'OwnershipTypeEntity',
            'Post',
            'QueryEntity',
            'Role',
            'SchemaFieldEntity',
            'Tag',
            'Test',
            'VersionedDataset',
        ],
        EntityWithRelationships: [
            'Assertion',
            'Chart',
            'Dashboard',
            'DataFlow',
            'DataJob',
            'DataProcessInstance',
            'Dataset',
            'MLFeature',
            'MLFeatureTable',
            'MLModel',
            'MLModelGroup',
            'MLPrimaryKey',
        ],
        HyperParameterValueType: ['BooleanBox', 'FloatBox', 'IntBox', 'StringBox'],
        OwnerType: ['CorpGroup', 'CorpUser'],
        PlatformSchema: ['KeyValueSchema', 'TableSchema'],
        ResultsType: ['StringBox'],
        TimeSeriesAspect: [
            'AssertionRunEvent',
            'DashboardUsageMetrics',
            'DataProcessRunEvent',
            'DatasetProfile',
            'Operation',
        ],
    },
};
export default result;
